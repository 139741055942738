<template>
  <svg
    class="tp-icon"
    viewBox="0 0 24 24"
    :width="size"
    :height="size"
  >
    <use :href="`img/unicons.svg#${name}`" />
  </svg>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: 'exclamation-triangle',
    },
    size: {
      type: Number,
      default: 19,
    },
  },
};
</script>

<style lang="scss">
.tp-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  fill: currentColor;
}
</style>
