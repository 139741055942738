<template>
  <div class="tp-card">
    <slot />
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
@import './resources/css/base/variables';

.tp-card {
  background-color: $bg-card;
  border-radius: $default-radius;
  margin-bottom: map-get($spaces, margin);
  padding: map-get($spaces, padding);
  box-shadow: $shadow;
  width: 100%;
  text-align: center;
}
</style>
