<template>
  <div class="tp-button" @click="$emit('click')" :class="{ 'tp-button--has-margin': margin }">
    <tp-icon class="tp-button__icon" :name="icon" v-if="icon" />
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: '',
    },
    margin: {
      type: Boolean,
      default: false,
    }
  }
};
</script>

<style lang="scss">
@import './resources/css/base/variables';

.tp-button {
  color: map-get($colors, white);
  background: get-color(primary);
  padding: 15px 20px;
  border-radius: $btn-radius;
  display: flex;
  align-items: center;
  transition: .2s all;
  justify-content: center;
  cursor: pointer;

  &--has-margin {
    margin-bottom: 24px;
  }

  &__icon {
    margin-right: 12px;
  }

  &:hover {
    transform: scale(0.9);
    box-shadow: $btn-box-shadow;
  }
}
</style>
